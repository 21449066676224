import { useRef, useState } from "react";
import "./contact.scss";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

const variants = {
  initial: {
    y: 500,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};

const Contact = () => {
  const formRef = useRef();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);

  const services = [
    "Commercials",
    "Real Estate",
    "Product Commercials",
    "Content Creation & Marketing",
    "Drone Shooting",
    "Corporate Events & Custom Services",
  ];

  const handleServiceChange = (service) => {
    setSelectedServices((prev) =>
        prev.includes(service)
            ? prev.filter((s) => s !== service)
            : [...prev, service]
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = {
      name: formRef.current.name.value,
      email: formRef.current.email.value,
      selectedServices: selectedServices.join(", "),
      message: formRef.current.message.value,
    };

    emailjs
        .send(
            "service_5szusoi", // Replace with your Email.js service ID
            "template_8zm1jyp", // Replace with your Email.js template ID
            formData, // Form data object
            "oTas9QxwqwL8fmDEq" // Replace with your Email.js public key
        )
        .then(
            () => {
              setSuccess(true);
              setError(false);
              formRef.current.reset(); // Reset the form on successful submission
              setSelectedServices([]); // Clear selected services
            },
            (error) => {
              console.error("Email sending error:", error);
              setError(true);
              setSuccess(false);
            }
        );
  };

  return (
      <motion.div
          className="contact"
          variants={variants}
          initial="initial"
          animate="animate"
      >
        {/* Text Container */}
        <motion.div className="textContainer" variants={variants}>
          <motion.h1 variants={variants}>
            Let’s work <span className="orange">together!</span>
          </motion.h1>
          {/* Desktop: Mail, Address, and Phone */}
          <motion.div className="item hidden sm:block" variants={variants}>
            <h2>Mail</h2>
            <span>office@spectrumstudios.ro</span>
          </motion.div>
          <motion.div className="item hidden sm:block" variants={variants}>
            <h2>Address</h2>
            <span>Strada Vasile Alecsandri 1, Oradea</span>
          </motion.div>
          <motion.div className="item hidden sm:block" variants={variants}>
            <h2>Phone</h2>
            <a href="tel:+40 (720) 909 792">+40 (720) 909 792</a>
          </motion.div>
        </motion.div>

        {/* Form Container */}
        <div className="formContainer">
          <motion.form ref={formRef} onSubmit={sendEmail}>
            <input type="text" required placeholder="Name" name="name"/>
            <input type="email" required placeholder="Email" name="email"/>
            <div
                className={`dropdown ${isDropdownOpen ? "open" : ""}`}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="dropdown-button flex items-center justify-between">
          <span className="flex-grow">
            {selectedServices.length > 0
                ? selectedServices.join(", ")
                : "Select Services"}
          </span>
                <span
                    className={`arrow ${
                        isDropdownOpen ? "open" : "closed"
                    } ml-2`}
                >
            {isDropdownOpen ? "▼" : "◀"}
          </span>
              </div>
              {isDropdownOpen && (
                  <ul className="dropdown-content">
                    {services.map((service) => (
                        <li
                            key={service}
                            className={`dropdown-item ${
                                selectedServices.includes(service) ? "selected" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleServiceChange(service);
                            }}
                        >
                          {service}
                          {selectedServices.includes(service) && (
                              <span className="checkmark">✔</span>
                          )}
                        </li>
                    ))}
                  </ul>
              )}
            </div>
            <textarea rows={4} placeholder="Message" name="message"/>
            <button className="text-2xl text-white">SUBMIT</button>
            {error && (
                <p className="error">Something went wrong. Please try again.</p>
            )}
            {success && (
                <p className="success">Your message was sent successfully!</p>
            )}
          </motion.form>
          {/* Mobile: Mail, Address, and Phone */}
          <div className="item mt-4 block sm:hidden">
            <h2>Mail</h2>
            <span>office@spectrumstudios.ro</span>
          </div>
          <div className="item mt-2 block sm:hidden">
            <h2>Address</h2>
            <span>Strada Vasile Alecsandri 1, Oradea</span>
          </div>
          <div className="item mt-2 block sm:hidden">
            <h2>Phone</h2>
            <a href="tel:+40 (720) 909 792">+40 (720) 909 792</a>
          </div>
        </div>
      </motion.div>

  );
};

export default Contact;
