import { useRef } from "react";
import "./portfolio.scss";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { useMediaQuery } from "react-responsive";

const items = [
  {
    id: 1,
    title: "Sapient Imobiliare",
    img: "./sapient.jpg",
    desktopDesc:
        "We partnered with Sapient Imobiliare to showcase their premium real estate portfolio through high-quality videography and drone footage. Our team created cinematic property tours that highlight the unique features of each property, engaging potential buyers and boosting Sapient's online presence. This collaboration significantly enhanced their marketing strategy, emphasizing professionalism and luxury in every frame.",
    mobileDesc:
        "Showcased premium real estate with cinematic property tours, boosting Sapient's online presence and emphasizing luxury.",
  },
  {
    id: 2,
    title: "Hilton Oradea",
    img: "./hilton.jpeg",
    desktopDesc:
        "For Hilton Oradea, we provided top-notch content creation services to promote their luxury hospitality offerings. Our team developed a series of visually stunning promotional videos and lifestyle imagery that captured the elegance and sophistication of the Hilton brand. The content was tailored for social media campaigns, helping Hilton Oradea attract a wider audience and establish a strong digital presence.",
    mobileDesc:
        "Created stunning promotional videos and imagery, enhancing Hilton's digital presence and attracting a wider audience.",
  },
  {
    id: 3,
    title: "Huta Slavia",
    img: "./huta.jpg",
    desktopDesc:
        "Our collaboration with Huta Slavia focused on creating captivating content for their eco-tourism initiatives. We produced immersive videos featuring the natural beauty of the location, drone shots of the breathtaking landscapes, and engaging stories about their sustainable practices. The result was a powerful visual narrative that resonated with environmentally conscious travelers, helping Huta Slavia increase bookings and brand loyalty.",
    mobileDesc:
        "Produced immersive eco-tourism content, highlighting natural beauty and sustainability to engage conscious travelers.",
  },
  {
    id: 4,
    title: "Giorgio Mare",
    img: "./giorgio.jpg",
    desktopDesc:
        "Giorgio Mare enlisted our services to promote their premium store specializing in authentic Italian seafood and culinary delicacies. We crafted a series of captivating videos highlighting their high-quality products, artisanal offerings, and the rich traditions behind their flavors. Through a blend of elegant close-ups of their finest specialties and inviting visuals of the store’s ambiance, we helped Giorgio Mare connect with their audience, enhancing both in-store visits and online engagement.",
    mobileDesc:
        "Showcased the store's premium Italian seafood and specialties through captivating videos.",
  },
  {
    id: 5,
    title: "QubMenu",
    img: "./qubmenu.jpg",
    desktopDesc:
        "We collaborated with QubMenu, a pioneer in digital menus for restaurants, to create engaging video and photography content. Our team captured the innovative design and seamless functionality of their products, showcasing how QubMenu enhances the dining experience. The final visuals effectively highlight their technology, supporting QubMenu's marketing and brand promotion efforts.",
    mobileDesc:
        "Highlighted QubMenu's innovative digital menus through engaging visuals showcasing their seamless functionality.",
  },
];

const Single = ({ item, isMobile }) => {
  const ref = useRef();
  const { scrollYProgress } = useScroll({
    target: ref,
  });
  const y = useTransform(scrollYProgress, [0, 1], [-300, 300]);

  return (
      <section>
        <div className="container">
          <div className="wrapper">
            <div className="imageContainer" ref={ref}>
              <img src={item.img} alt={item.title} />
            </div>
            <motion.div className="textContainer" style={{ y }}>
              <h2>{item.title}</h2>
              <p>{isMobile ? item.mobileDesc : item.desktopDesc}</p>
            </motion.div>
          </div>
        </div>
      </section>
  );
};

const Portfolio = () => {
  const ref = useRef();
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  // Detect screen size
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
      <div className="portfolio" ref={ref}>
        <div className="progress">
          <h1>
            Featured <span className="text-white">Works</span>
          </h1>
          <motion.div style={{ scaleX }} className="progressBar"></motion.div>
        </div>
        {items.map((item) => (
            <Single item={item} isMobile={isMobile} key={item.id} />
        ))}
      </div>
  );
};

export default Portfolio;
