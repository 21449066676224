import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const videos = [
    {
        desktop: "/videos/services/content-creation-desktop.mp4",
        mobile: "/videos/services/content-creation-mobile.mp4",
        label: "Content Creation",
        description: `
            Elevate your brand with our expert content creation services. 
            We craft compelling visuals and engaging stories tailored to your unique voice, 
            helping you connect with your audience. From captivating videos to dynamic social media campaigns, 
            we bring your ideas to life, driving impact and building a strong presence in the digital world.`,
        mobileDescription: `
      Boost your brand with creative, engaging content that connects and inspires. From videos to social campaigns, we bring your vision to life.`,
    },
    {
        desktop: "/videos/services/drone-desktop.mp4",
        mobile: "/videos/services/drone-mobile.mp4",
        label: "Drone Footage",
        description: `
      Experience the world from a new perspective with our state-of-the-art drone videography services. 
      Perfect for showcasing landscapes, events, or architectural marvels, our drone footage delivers breathtaking 
      aerial views with impeccable clarity.`,
        mobileDescription: `
      Explore new perspectives with drone videography.`,
    },
    {
        desktop: "/videos/services/commercial-desktop.mp4",
        mobile: "/videos/services/commercial-mobile.mp4",
        label: "Commercial Videos",
        description: `
      Drive your business forward with high-quality, cinematic commercials that connect with your audience. 
      Our team creates compelling narratives, combining stunning visuals and storytelling to elevate your brand.`,
        mobileDescription: `
      Drive your business with cinematic commercials.`,
    },
    {
        desktop: "/videos/services/real-estate-desktop.mp4",
        mobile: "/videos/services/real-estate-mobile.mp4",
        label: "Real Estate",
        description: `
      Transform the way your properties are showcased with immersive real estate services. Our professional 
      videography highlights every detail of your property, from elegant interiors to captivating outdoor spaces. 
      We create videos that inspire potential buyers, helping you stand out in a competitive market.`,
        mobileDescription: `
      Showcase your properties with immersive videos.`,
    },
    {
        desktop: "/videos/services/documentary-desktop.mp4",
        mobile: "/videos/services/documentary-mobile.mp4",
        label: "Documentaries",
        description: `
        Discover the power of storytelling with our documentary filmmaking services. 
        We specialize in creating visually stunning and emotionally engaging films that capture the heart of your narrative. 
        From intimate personal stories to expansive explorations of real-world issues, our documentaries bring every detail to life, 
        leaving a meaningful and lasting impact.`,
        mobileDescription: `
      Bring stories to life with impactful documentaries that captivate, inform, and inspire.`,
    },
];

export default function Services() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTimerActive, setIsTimerActive] = useState(true);

    const handlePrev = () => {
        setIsTimerActive(false);
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? videos.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setIsTimerActive(false);
        setCurrentIndex((prevIndex) =>
            prevIndex === videos.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        if (isTimerActive) {
            const timer = setTimeout(() => {
                setCurrentIndex((prevIndex) =>
                    prevIndex === videos.length - 1 ? 0 : prevIndex + 1
                );
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [currentIndex, isTimerActive]);

    return (
        <div className="relative w-full h-full md:h-full" style={{ fontFamily: 'Poppins, sans-serif' }}>
            {/* Carousel Container */}
            <div className="relative overflow-hidden h-full">
                <AnimatePresence>
                    {videos.map((video, index) =>
                        index === currentIndex ? (
                            <motion.div
                                key={index}
                                className="absolute inset-0"
                                initial={{ x: "100%" }}
                                animate={{ x: "0%" }}
                                exit={{ x: "-100%" }}
                                transition={{ duration: 0.6 }}
                                drag="x"
                                dragConstraints={{ left: 0, right: 0 }}
                                dragElastic={0.2}
                                onDragEnd={(event, info) => {
                                    if (info.offset.x < -50) handleNext();
                                    if (info.offset.x > 50) handlePrev();
                                }}
                            >
                                <div className="absolute inset-0 bg-black bg-opacity-40 z-10"></div>
                                {/* Desktop Video */}
                                <video
                                    className="hidden w-full h-full object-cover md:block"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    style={{ pointerEvents: "none" }}
                                >
                                    <source src={video.desktop} type="video/mp4" />
                                </video>
                                {/* Mobile Video */}
                                <video
                                    className="block w-full h-full object-cover opacity-70 md:hidden"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    style={{ pointerEvents: "none" }}
                                >
                                    <source src={video.mobile} type="video/mp4" />
                                </video>
                            </motion.div>
                        ) : null
                    )}
                </AnimatePresence>
            </div>

            {/* Navigation Controls */}
            <button
                onClick={handlePrev}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-75 text-white rounded-full p-3 hover:bg-opacity-90 z-30"
            >
                <ChevronLeft />
            </button>
            <button
                onClick={handleNext}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-75 text-white rounded-full p-3 hover:bg-opacity-90 z-30"
            >
                <ChevronRight />
            </button>

            {/* Labels and Descriptions */}
            {/* On mobile: bottom-centered; On desktop: bottom-left aligned */}
            <motion.div
                className="
          absolute bottom-16 px-6 w-full text-center
          md:text-left md:w-auto md:left-16 md:bottom-16
          z-20 pointer-events-none
        "
                initial={{opacity: 0, y: 30}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.8}}
            >
                <motion.h5
                    className="
            text-4xl sm:text-5xl md:text-6xl font-extrabold uppercase
            text-transparent bg-clip-text
            bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500
            drop-shadow-lg tracking-tight
          "
                    initial={{scale: 0.8, y: 20}}
                    animate={{scale: 1, y: 0}}
                    transition={{duration: 0.6}}
                    style={{lineHeight: 1.1}}
                >
                    {videos[currentIndex].label}
                </motion.h5>
                <motion.p
                    className="mt-4 text-sm sm:text-base mb-16 md:text-lg font-medium text-white max-w-md md:max-w-xl leading-relaxed pointer-events-auto"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 1}}
                >
                    {window.innerWidth < 768
                        ? videos[currentIndex].mobileDescription.trim()
                        : videos[currentIndex].description.trim()}
                </motion.p>
            </motion.div>
        </div>
    );
}
